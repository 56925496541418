// import React from "react"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/bh-videos/bh-videos.style.css"
import BHDueDiligenceModal from "../../components/bh-due-diligence-modal"
import useModalProps from "../../helpers/use-modal-props"
import "swiper/swiper.css"
import { graphql, useStaticQuery } from "gatsby"

function DueDiligencePlayer() {
  const duediligencevideosacf = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDoyMTg3") {
          id
          duediligencevideosacf {
            disclosuresModalData {
              content {
                fieldGroupName
                text
                title
              }
              fieldGroupName
              title
            }
            modalAcceptButtonLabel
            fieldGroupName
            modalCloseButtonLabel
            modalFooterText
            riskFactorsModalData {
              content {
                fieldGroupName
                text
                title
              }
              fieldGroupName
              title
            }
          }
        }
      }
    }
  `)

  const { modalProps } = useModalProps({ initialOpen: initialOpen })
  const { modalStatus } = modalProps

  var initialOpen = true

  if (typeof window !== "undefined") {
    if (
      window.localStorage.length == 0 ||
      window.localStorage.getItem("bh-disclosureNoticesStatus") !== "accepted"
    ) {
      initialOpen = false
    }
  }

  return (
    <Layout>
      <SEO title="Due Diligence Videos" />
      <div className="p-container-wrap txt-center" style={{ color: "#BDB4A8" }}>
        <div className="breadcrumb">
          <a href="/due-diligence-videos">DUE DILIGENCE</a> / INTRODUCTION TO
          BREVAN HOWARD
        </div>
        <h2 className="txt-dark txt-h2">Introduction to Brevan Howard</h2>
        <h4 className="txt-dark txt-player-title ">
          with Danny Bernheim, Senior Managing Director, Client Partnership
          Group
        </h4>
      </div>
      <div className="player-container">
        <div className="player-wrapper">
          <div className="player-iframe-container">
            <iframe
              className="vimeo-iframe responsive-iframe"
              src="https://player.vimeo.com/video/914831332?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="740"
              height="460"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>

      <BHDueDiligenceModal
        modalProps={modalProps}
        digitalassetsacf={
          duediligencevideosacf.wpgraphql.page.duediligencevideosacf
        }
      />
    </Layout>
  )
}

export default DueDiligencePlayer
